import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.parallux.js'
import '../../assets/scripts/jquery.nicescroll.min'
if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      $('body').niceScroll({
        mousescrollstep: 40,
        scrollspeed: 80,
        zindex: 101,
        cursorcolor: '#BDBDBD',
        cursorborder: '1px solid #BDBDBD',
        cursorwidth: '5px',
        cursoropacitymax: 0.6,
        smoothscroll: true,
        iframeautoresizeL: true,
      });
    }
  }
  function resizeNiceScroll () {
    if (window.devicePixelRatio < 2) {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            setTimeout(() => {
                $('body').getNiceScroll().resize()
            }, 500);
        }
    }
  }

window.onload = function() {
  var endTime = (new Date()).getTime();
  var millisecondsLoading = endTime - startTime;
  if(millisecondsLoading > 3000){
      $('.loading').fadeOut()
      $('.comp-root').css({
          height: 'auto',
          overflowY: 'auto'
      })
      resizeNiceScroll ()
  }else{
      setTimeout(function(){
          $('.loading').fadeOut()
          $('.comp-root').css({
              height: 'auto',
              overflowY: 'auto'
          })
          resizeNiceScroll ()
      },2000)
  }
};


var interleaveOffset = 0.5; //视差比值
var swiperOptions = {
    loop: true,
    speed: 800,
    grabCursor: true,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    on: {
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[ i ].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[ i ].querySelector("#banner-swiper b").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[ i ].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[ i ].style.transition = speed + "ms";
                swiper.slides[ i ].querySelector("#banner-swiper b").style.transition =
                    speed + "ms";
            }
        },
    },
};
var swiper = new Swiper("#banner-swiper", swiperOptions);
let nav = $(".section2 .pic a");
let nei = $(".section2 .content");
nav.mouseover(function () {
    nav.attr("class", "");
    nei.css("display", "none");
    $(this).addClass("active");
    nei.eq($(this).index()).css("display", "block");
})


// 滚动效果
//滚动文字效果
if ($(window).width() > 1024) {
    var beforeScroH = $(document).scrollTop();
    $(document).scroll(function () {
        var scroH = $(document).scrollTop(); //滚动高度
        var viewH = $(window).height(); //可见高度
        var contentH = $(document).height(); //内容高度

        var section4Top = $(".section4").offset().top
        var section4H = $(".parallux:nth-child(1) .parallux-bg").height()


        if (scroH >= section4Top && scroH < section4Top + section4H / 2) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity", "0")
          $(".section4 .parallux .text").css("z-index", "1")
          $(".section4 .parallux:nth-child(1) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(1) .text").css("z-index", "2")
          console.log(1);
        }
        else if (scroH >= section4Top + section4H / 2 && scroH < section4Top + section4H + section4H / 2) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity", "0")
          $(".section4 .parallux .text").css("z-index", "1")
          $(".section4 .parallux:nth-child(2) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(2) .text").css("z-index", "2")
        } else if (scroH >= section4Top + section4H + section4H / 2 && scroH < section4Top + section4H*2 + section4H / 2) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity","0")
          $(".section4 .parallux .text").css("z-index","1")
          $(".section4 .parallux:nth-child(3) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(3) .text").css("z-index", "2")
        }else if (scroH >= section4Top + section4H*2 + section4H / 2 && scroH < section4Top + section4H*3 + section4H / 2) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity","0")
          $(".section4 .parallux .text").css("z-index","1")
          $(".section4 .parallux:nth-child(4) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(4) .text").css("z-index", "2")
        }else if (scroH >= section4Top + section4H*3 + section4H / 2 && scroH < section4Top + section4H*4 + section4H / 2) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity","0")
          $(".section4 .parallux .text").css("z-index","1")
          $(".section4 .parallux:nth-child(5) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(5) .text").css("z-index", "2")
        }else if (scroH >= section4Top + section4H*4 + section4H / 2 && scroH < section4Top + section4H*5 + section4H / 2) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity","0")
          $(".section4 .parallux .text").css("z-index","1")
          $(".section4 .parallux:nth-child(6) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(6) .text").css("z-index", "2")
        }else if (scroH >= section4Top + section4H*5 + section4H / 2 && scroH < section4Top + section4H*6 + section4H / 2) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity","0")
          $(".section4 .parallux .text").css("z-index","1")
          $(".section4 .parallux:nth-child(7) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(7) .text").css("z-index", "2")
        }else if (scroH >= section4Top + section4H*6 + section4H / 2 && scroH < $(".section5").offset().top - section4H) {
          $(".section4 .parallux .text").addClass("fix")
          $(".section4 .parallux .text").css("opacity","0")
          $(".section4 .parallux .text").css("z-index","1")
          $(".section4 .parallux:nth-child(8) .text").css("opacity", "1")
          $(".section4 .parallux:nth-child(8) .text").css("z-index", "2")
        }
        else {
            $(".section4 .text").removeClass("fix")
        }
    });
}